<template>
  <v-card v-if="record"
          tile
          flat
          max-width="720"
          color="mx-auto">
    <v-overlay v-if="!urlValid">
      <v-progress-circular indeterminate/>
    </v-overlay>
    <v-card-title>
      健康情况
    </v-card-title>
    <v-card-text>
      <v-card-subtitle>
        主要问题
      </v-card-subtitle>
      <v-card-text>
        {{ record.hpi }}
      </v-card-text>
      <v-card-subtitle>
        刻下问题
      </v-card-subtitle>
      <v-card-text>
        {{ record.currentSymptoms }}
      </v-card-text>
      <v-card-subtitle>
        既往问题
      </v-card-subtitle>
      <v-card-text>
        {{ record.history }}
      </v-card-text>
    </v-card-text>

    <v-divider/>

    <v-card-title class="pb-0">
      健康判断
    </v-card-title>
    <v-card-text v-if="record.diagnoses" class="d-flex justify-end">
      <div class="mr-2 font-weight-black">结论:</div>
      <div style="width: 33%" class="d-flex flex-column">
        <div v-for="(d, i) in record.diagnoses"
             :key="'d'+i"
             :class="{'font-weight-bold': !d.includes('疑似')}"
        >
          {{ d }}
        </div>
      </div>
    </v-card-text>
    <v-card-text v-else>
      您的健康专员正在为您进行健康判断, 请稍后查看~
    </v-card-text>
  </v-card>
</template>

<script>
import DiagnoseSelector from "../components/DiagnoseSelector";

export default {
  name: "Report",
  components: {DiagnoseSelector},
  data: () => ({
    urlValid: false,
    record: null
  }),
  beforeCreate() {
    const shortUrl = this.$route.params.shortUrl
    this.$store.commit('updateState', {key: 'token', value: shortUrl})
    this.$store.dispatch('request', {
      action: 'checkUrl',
      params: {shortUrl, route: 'report'},
      fn: () => {
        this.urlValid = true
        this.$store.dispatch('request', {
          action: 'getRenderedRecord',
          params: {},
          triggerLoading: false,
          fn: data => {
            this.record = data
          }
        })
      },
      fail: () => {
        // alert('NotFound')
        this.$router.push({name: 'NotFound', params: {shortUrl: '404'}})
      }
    })
  }
}
</script>

<style scoped>

</style>